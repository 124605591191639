import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_RESEARCH_API_URL;
const API_STAGE = process.env.REACT_APP_API_STAGE || 'dev';

if (!API_BASE_URL) {
  console.error('API_BASE_URL is not defined. Check your environment variables.');
}

const api = axios.create({
  baseURL: `${API_BASE_URL}/${API_STAGE}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add some debug logging
console.log('API Base URL:', API_BASE_URL);
console.log('Full API URL:', `${API_BASE_URL}/${API_STAGE}`);

export const researchApi = {
  createResearch: async (userID, researchQuestion, researchDescription, researchName) => {
    try {
      const response = await api.post('/research/create', {
        userID,
        researchName,
        researchDescription,
        researchQuestion
      });
      console.log('Create Research Response:', response);
      return response;
    } catch (error) {
      console.error('Failed to create research:', error);
      throw error;
    }
  },

  getResearch: async (userID) => {
    try {
      const response = await api.get(`/research/${userID}`);
      console.log('Get all research response:', response);
      return response;
    } catch (error) {
      console.error('Failed to get research:', error);
      throw error;
    }
  },

  getQuestions: async (userID) => {
    try {
      const response = await api.get(`/research/questions/${userID}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch questions');
    }
  },

  updateQuestions: async (userID, researchID, questions) => {
    try {
      const response = await api.post('/research/update_questions', {
        userID,
        researchID,
        questions
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to update questions');
    }
  },

  createPersonas: async (researchID, numberOfPersonas, filters) => {
    try {
      console.log('Creating personas with:', { researchID, numberOfPersonas, filters });
      
      // Initial request to start persona generation
      const response = await api.post('/personas/create', {
        researchID,
        count: numberOfPersonas, // Make sure we're using the correct parameter name
        ageRange: filters.ageRange,
        region: filters.region,
        incomeRange: filters.incomeRange
      });
      
      console.log('Initial response:', response.data);
      
      // Check if the request was accepted
      if (!response.data || response.data.error) {
        throw new Error(response.data?.error || 'Failed to initiate persona generation');
      }

      // Poll for completion
      let retries = 0;
      const maxRetries = 10;
      const delay = 2000; // 2 seconds between polls

      while (retries < maxRetries) {
        await new Promise(resolve => setTimeout(resolve, delay));
        
        try {
          // Get the current personas
          const pollResponse = await api.get(`/personas/${researchID}`);
          console.log('Poll response:', pollResponse.data);

          // Check if we have personas
          if (pollResponse.data && Array.isArray(pollResponse.data.personas)) {
            return {
              personas: pollResponse.data.personas
            };
          }
        } catch (pollError) {
          console.log('Polling attempt failed:', pollError);
          // Continue polling even if this attempt failed
        }

        retries++;
      }

      throw new Error('Timeout waiting for personas to be generated');
    } catch (error) {
      console.error('Create personas error details:', error.response?.data || error);
      throw new Error(error.response?.data?.error || error.message || 'Failed to create personas');
    }
  },

  getPersonas: async (researchID) => {
    try {
      const response = await api.get(`/personas/${researchID}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch personas');
    }
  },

  updatePersonas: async (userID, researchID, currentPersonaIDs, filters) => {
    try {
      const response = await api.post('/personas/update', {
        userID,
        researchID,
        currentPersonaIDs,
        ...filters
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to update personas');
    }
  },

  getResponses: async (researchID) => {
    try {
      const response = await api.get(`/responses/${researchID}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch responses');
    }
  },

  createResponses: async (researchID, userID, questionIDs, personaIDs) => {
    try {
      const response = await api.post('/responses/create', {
        researchID,
        userID,
        questionIDs,
        personaIDs
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to create responses');
    }
  },

  removeResearch: async (userID, researchID) => {
    try {
      const response = await api.post('/research/remove', {
        userID,
        researchID
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to remove research');
    }
  }
};