import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

export function PaymentForm({ amount, onSuccess, onError, selectedPlan, currentSubscription }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        onError(error);
        return;
      }

      onSuccess(paymentMethod);
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='textbox container'>
    <form onSubmit={handleSubmit} style={{ maxWidth: '70%', margin: '0 auto' }}>
            <CardElement />
            {amount > 0 && (
                <p>Amount to pay: €{amount}</p>
            )}
            <button className="button standout-button" type="submit" disabled={!stripe || loading}>
                {loading ? 'Processing...' : 'Pay'}
            </button>
    </form>
    </div>
  );
}