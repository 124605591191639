import { api } from './authenticationApi'; 

export const paymentApi = {
  createPaymentIntent: async (amount, credits, task) => {
    if (!amount || !credits) {
      throw new Error('Amount and credits are required');
    }
    const response = await api.makeAuthenticatedRequest('/payments/create-payment-intent', {
      method: 'POST',
      body: { amount, credits, task }
    });
    return response;
  },

    createSubscription: async (selectedPlan, paymentMethodId, userEmail) => {
      try {
        const response = await api.makeAuthenticatedRequest('/subscriptions/create-subscription', {
          method: 'POST',
          body: {
            selectedPlan,
            paymentMethod: { id: paymentMethodId },
            userEmail
          }
        });
  
        // Add error logging
        console.log('Subscription API Response:', response);
  
        if (!response) {
          throw new Error('No response received from server');
        }
  
        return response;  // This should include either clientSecret or status: 'subscription_updated'
      } catch (error) {
        console.error('Subscription API Error:', error);
        throw error;
      }
    },

  getUserDetails: async () => {
    const response = await api.makeAuthenticatedRequest('/payments/user-details', {
      method: 'GET'
    });
    
    if (!response) {
      throw new Error('Failed to fetch user details');
    }
    
    return response;
  }
};