import React, { useEffect, useRef } from 'react';

const NetworkAnimation = () => {
  const networkRef = useRef(null);
  const nodesRef = useRef([]);
  const connectionsRef = useRef([]);
  const columnsRef = useRef(5);
  const rowsRef = useRef(6);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    const createNetwork = () => {
      if (!networkRef.current) return;
      
      networkRef.current.innerHTML = '';
      nodesRef.current = [];
      connectionsRef.current = [];

      const nodeCount = 20;
      
      for (let i = 0; i < nodeCount; i++) {
        const node = document.createElement('div');
        node.classList.add('network-node');
        
        const column = Math.floor(Math.random() * columnsRef.current);
        const row = Math.floor(Math.random() * rowsRef.current);
        const xPos = (column * 18) + 13;
        const yPos = (row * 5) + 34;
        
        node.style.left = `${xPos}vw`;
        node.style.top = `${yPos}vh`;
        networkRef.current.appendChild(node);
        nodesRef.current.push(node);
      }

      for (let i = 0; i < nodeCount - 1; i++) {
        createConnection(nodesRef.current[i], nodesRef.current[i + 1]);
      }

      for (let i = 0; i < nodeCount / 2; i++) {
        const startNodeIndex = Math.floor(Math.random() * nodeCount);
        let endNodeIndex;
        do {
          endNodeIndex = Math.floor(Math.random() * nodeCount);
        } while (endNodeIndex === startNodeIndex);

        createConnection(nodesRef.current[startNodeIndex], nodesRef.current[endNodeIndex]);
      }
    };

    const createConnection = (startNode, endNode) => {
      const connection = document.createElement('div');
      connection.classList.add('network-connection');
      networkRef.current.appendChild(connection);
      connectionsRef.current.push({
        element: connection,
        startNode,
        endNode
      });
    };

    const updateConnection = (connection, startNode, endNode) => {
      const rect1 = startNode.getBoundingClientRect();
      const rect2 = endNode.getBoundingClientRect();
      const containerRect = networkRef.current.getBoundingClientRect();
      
      const x1 = rect1.left - containerRect.left + rect1.width / 2;
      const y1 = rect1.top - containerRect.top + rect1.height / 2;
      const x2 = rect2.left - containerRect.left + rect2.width / 2;
      const y2 = rect2.top - containerRect.top + rect2.height / 2;
      
      const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
      const angle = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
      
      connection.style.width = `${length}px`;
      connection.style.left = `${x1}px`;
      connection.style.top = `${y1}px`;
      connection.style.transform = `rotate(${angle}deg)`;
    };

    const updatePositions = () => {
      nodesRef.current.forEach(node => {
        const targetColumn = Math.floor(Math.random() * columnsRef.current);
        const targetRow = Math.floor(Math.random() * rowsRef.current);
        const xPos = (targetColumn * 18) + 13;
        const yPos = (targetRow * 5) + 34;
        
        node.style.transition = 'all 2s ease-in-out';
        node.style.left = `${xPos}vw`;
        node.style.top = `${yPos}vh`;
      });
    };

    const updateConnections = () => {
      connectionsRef.current.forEach(({ element, startNode, endNode }) => {
        updateConnection(element, startNode, endNode);
      });
      animationFrameRef.current = requestAnimationFrame(updateConnections);
    };

    createNetwork();
    updateConnections();

    const interval = setInterval(updatePositions, 5000);

    return () => {
      clearInterval(interval);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return <div ref={networkRef} className="network-container" />;
};

export default NetworkAnimation;
