// client/src/components/Research/QuestionView.js
import React, { useState } from 'react';
import { QuestionType } from './ResearchConstants';
import editPencil from '../components/icons/edit_pencil.png';
import trashBin from '../components/icons/trash_bin.png';
import '../styles/ResearchPage.css';


const Question = ({ question, onEdit, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [questionText, setQuestionText] = useState(question.text);
  const [options, setOptions] = useState(question.options || []);

  const handleSave = () => {
    onEdit(question.id, { text: questionText, options });
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  const addOption = () => {
    setOptions([...options, '']);
  };

  const removeOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  return (
    <div className="research-grid-item">
        <div className="grid-content" style={{ minWidth: '90%' }}>
          {isEditing ? (
            <>
              <input
                className="question-option"
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <select 
                className="question-option"
                value={question.type} 
                onChange={(e) => onEdit(question.id, { type: e.target.value })}
              >
                <option value={QuestionType.OPEN_ENDED}>Open-Ended</option>
                <option value={QuestionType.MULTIPLE_CHOICE}>Multiple Choice</option>
                <option value={QuestionType.TRUE_FALSE}>True/False</option>
              </select>
              {(question.type === QuestionType.MULTIPLE_CHOICE || question.type === QuestionType.TRUE_FALSE) && (
                <div >
                  {options.map((option, index) => (
                    <div key={index}>
                      <input
                        className="question-option"
                        style={{ width: '60%' }}
                        value={option}
                        onChange={(e) => {
                          const newOptions = [...options];
                          newOptions[index] = e.target.value;
                          setOptions(newOptions);
                        }}
                        onKeyDown={handleKeyDown}
                      />
                      <button onClick={() => removeOption(index)} className="question-option" style={{ width: '10%', marginLeft: '0.5rem' }}>🗑</button>
                    </div>
                  ))}
                  <button className="research-button" onClick={addOption}>Add Option</button>
                </div>
              )}
              <button className="research-button" onClick={handleSave}>Save</button>
            </>
          ) : (
            <>
              <h4>{question.text}</h4>
              <div></div>
              <>{question.type}</>
              {options.length > 0 && (
                <div className="options">
                  {options.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </div>
              )}
            </>
          )}
      </div>
      <div className="button-container">
        <div onClick={() => setIsEditing(true)} style={{ maxWidth: '1.5rem', maxHeight: '1.5rem' }}>
          <img src={editPencil} alt="Edit" onClick={() => setIsEditing(true)} className="click-icon" />
        </div>
        <div onClick={() => onDelete(question.id)} style={{ maxWidth: '1.5rem', maxHeight: '1.5rem' }}>
          <img src={trashBin} alt="Delete" onClick={() => onDelete(question.id)} className="click-icon" />
        </div>
      </div>
    </div>
  );
};

export default Question;