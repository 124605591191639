import React from 'react';
import { useAuth } from '../authentication/AuthContext';
import { Link } from 'react-router-dom';
import '../index.css';

function MyAccountPage() {
  const { user, credits, subscription, firstName, email } = useAuth();

  return (
    <div className="home-page">
      <div className="hero hero-1 top-hero">
          <h1>My Account</h1>
          <div className="container">
            <h2>Account Details</h2>
            <p><strong>Name:</strong> {firstName || 'Not set'}</p>
            <p><strong>Email:</strong> {email || 'Not set'}</p>
            <button className="button">Change Password</button>
          </div>

          <div className="container">
            <h2>Subscription</h2>
            <p><strong>Current Plan:</strong> {subscription || 'FREE'}</p>
            <Link to="/payment" state={{ type: 'subscription' }} className="button">
              Upgrade Plan
            </Link>
          </div>

          <div className="container">
            <h2>Credits</h2>
            <p><strong>Available Credits:</strong> {credits || 0}</p>
            <Link to="/payment" state={{ type: 'credits' }} className="button">
              Top Up Credits
            </Link>
          </div>

          <div className="container">
            <h2>Usage History</h2>
            <div className="container">
              <span>Add history here: </span>
            </div>
          </div>
      </div>
    </div>
  );
}

export default MyAccountPage;