export const SUBSCRIPTION_TYPES = {
    FREE: {
      name: 'Free',
      price: 0,
      features: [
        '1 Basic Research per week',
        'Basic visualization',
        'Limited features'
      ]
    },
    BASIC: {
      name: 'Basic', 
      price: 25,
      features: [
        'Minimum 12 credits (resets weekly)',
        'Full survey history',
        'Basic visualization'
      ]
    },
    PREMIUM: {
      name: 'Premium',
      price: 40,
      features: [
        'Minimum 21 credits (resets weekly)',
        'Advanced AI analysis',
        'Premium insight reports'
      ]
    }
  };
  
  export const CREDIT_COSTS = {
    BASIC: {
      basicResearch: 2.00,  // 1 credit
      premiumResearch: 5.00 // 3 credits
    },
    PREMIUM: {
      basicResearch: 1.00,  // 1 credit
      premiumResearch: 2.00 // 3 credits
    }
  };
  
  // Price var is a multiplier whereby thats how much the total cost of credits is multipled by to be a cheaper deal
  // calc example: plan.premiumResearch / 3 * CREDIT_PACKAGES.amaount * CREDIT_PACKAGES.priceVar
  export const CREDIT_PACKAGES = [
    { amount: 5, priceVar: 0.9, description: 'Starter Pack' },
    { amount: 12, priceVar: 0.8, description: 'Weekly Pack' },
    { amount: 25, priceVar: 0.7, description: 'Value Pack' }
  ];