import React from 'react';
import '../index.css';
import '../styles/PersonasPage.css';
import { useAuth } from '../authentication/AuthContext';

function OurPersonas() {
  const { user } = useAuth();

  const personas = [
    {
      name: "Emily Blackwood",
      title: "Marketing Manager",
      age: 34,
      salary: "$85,000",
      image: require('../components/personas/Emily.jpeg')
    },
    {
      name: "James Callahan",
      title: "Product Owner",
      age: 39,
      salary: "$115,000",
      image: require('../components/personas/James.jpeg')
    },
    {
      name: "Sophie Thorne",
      title: "UX Researcher",
      age: 31,
      salary: "$78,000",
      image: require('../components/personas/Sophie.jpeg')
    },
    {
      name: "Thomas Whitman",
      title: "Digital Strategist",
      age: 36,
      salary: "$95,000",
      image: require('../components/personas/Thomas.jpeg')
    },
    {
      name: "Sarah Kingsley",
      title: "Data Analyst",
      age: 29,
      salary: "$72,000",
      image: require('../components/personas/Sarah.jpeg')
    },
    {
      name: "Daniel Hawthorne",
      title: "Content Strategist",
      age: 32,
      salary: "$68,000",
      image: require('../components/personas/Daniel.jpeg')
    },
    {
      name: "Laura Sinclair",
      title: "Brand Manager",
      age: 41,
      salary: "$92,000",
      image: require('../components/personas/Laura.jpeg')
    },
    {
      name: "Ryan Ashworth",
      title: "Social Media Manager",
      age: 28,
      salary: "$65,000",
      image: require('../components/personas/Ryan.jpeg')
    }
  ];

  return (
    <div className="personas-page">
      <h2>Get to know our personas below</h2>
      <div className="personas-grid">
        {personas.map((persona, index) => (
          <div className="persona-card" key={index}>
            <img 
              src={persona.image} 
              alt={`${persona.name}`}
              className="persona-image"
            />
            <h3>{persona.name}</h3>
            <h4>{persona.title}</h4>
            <div className="persona-details">
              <p><strong>Age:</strong> {persona.age}</p>
              <p><strong>Salary:</strong> {persona.salary}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="hero hero-3">
        <div className="hero-grid-item">
          <h1>Instantly understand your market with AI Surveys</h1>
        </div>
        {user ? (
          <a href="/research" className="button standout-button">Start Research</a>
        ) : (
          <a href="/login" className="button standout-button" onClick={() => { alert('You need to be logged in first.'); return false; }}>Start Research</a>
        )}
      </div>
    </div>
  );
}

export default OurPersonas;
