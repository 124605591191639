import React from 'react';
import { Link } from 'react-router-dom';
import { useFormSubmit } from '../services/useFormSubmit';

function CareersPage() {
  const { submitStatus, handleSubmit } = useFormSubmit();

  return (
    <div className="about-page">
      <div className="hero hero-1 top-hero">
        <h1>Join Our Team</h1>
        <p>Help us revolutionize market research with AI technology</p>
      </div>

      <div className="hero hero-2">
        <h2>What We're Looking For</h2>
        
        <div className="personas-grid">
          <div className="persona-card">
            <h3>Technical Developers</h3>
            <p>Join our development team to build and improve our AI-powered research platform.</p>
            <ul>
              <li>React & Node.js experience</li>
              <li>Interest in AI/ML technologies</li>
              <li>Problem-solving mindset</li>
            </ul>
          </div>

          <div className="persona-card">
            <h3>Business Developers</h3>
            <p>Help us grow our market presence and build relationships with key stakeholders.</p>
            <ul>
              <li>Strong communication skills</li>
              <li>Market research interest</li>
              <li>Business development experience</li>
            </ul>
          </div>

          <div className="persona-card">
            <h3>Testers</h3>
            <p>Ensure the quality and reliability of our platform through comprehensive testing.</p>
            <ul>
              <li>Detail-oriented approach</li>
              <li>Quality assurance experience</li>
              <li>User experience focus</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="hero hero-1">
        <h2>Student Opportunities</h2>
        <div className="textbox">
          <p>We actively prioritize hiring students who are completing their studies. We offer flexible arrangements that complement your academic commitments while providing valuable industry experience.</p>
        </div>
      </div>

      <div className="hero hero-3">
        <h2>How to Apply</h2>
        <div className="textbox">
          <p>Check out our <a href="https://www.linkedin.com/company/instantflows" target="_blank" rel="noopener noreferrer">LinkedIn page</a> for current job openings.</p>
          <p>Interested in future opportunities? Submit your application below:</p>
          
          <form 
            id="careers-form" 
            action="https://script.google.com/macros/s/AKfycbxr330lHfFdEuwlIhhJAfi-NNTT4vXd7Xbgy58V4_pYxGkVgKelK9vU0wOuRmiShb_u6w/exec" 
            method="POST" 
            className="interest-form"
            onSubmit={(e) => handleSubmit(e, 'careers-form')}
          >
            <input type="text" name="Name" placeholder="Your Name" required />
            <input type="email" name="Email" placeholder="Your Email" required />
            <select name="Interest" required>
              <option value="">Select Your Area of Interest</option>
              <option value="Technical">Technical Development</option>
              <option value="Business">Business Development</option>
              <option value="Testing">Testing</option>
              <option value="Other">Other</option>
            </select>
            <textarea name="Message" placeholder="Tell us about yourself and your interest in InstantFlows. Make sure to include important links so we can find you!" required></textarea>
            <button type="submit" className="button">Submit Application</button>
            {submitStatus.message && (
              <div className={submitStatus.isError ? 'error-message' : 'success-message'}>
                {submitStatus.message}
              </div>
            )}
          </form>
        </div>

      </div>
    </div>
  );
}

export default CareersPage;