import { researchApi } from './researchApi';
import { QuestionType } from '../research/ResearchConstants';


// Poll research status until complete or error
  export const pollResearchStatus = async (userID, researchID) => {
    const maxAttempts = 30;
    const pollInterval = 8000;
    let attempts = 0;
  
    while (attempts < maxAttempts) {
      const responseBody = await researchApi.getResearch(userID);
      const researchData = responseBody.data

      // Find the specific research with the given researchID
      const research = researchData.find(r => r.researchID === researchID);
  
      if (!research) {
        throw new Error('Research not found');
      }
  
      if (research.statusQuestions === 'COMPLETED') {
        return research;
      } else if (research.statusQuestions === 'ERROR') {
        throw new Error('Failed to generate questions');
      }
  
      await new Promise(resolve => setTimeout(resolve, pollInterval));
      attempts++;
    }
  
    throw new Error('Timeout waiting for research completion');
  };
  
  
  // Map API question types to frontend types
  export const mapQuestionType = (apiType) => {
    const typeMap = {
      'multiple_choice': QuestionType.MULTIPLE_CHOICE,
      'open': QuestionType.OPEN_ENDED,
      'true_false': QuestionType.TRUE_FALSE
    };
    return typeMap[apiType] || QuestionType.OPEN_ENDED;
  };
  
  // Map frontend question types to API types
  export const mapQuestionTypeToApi = (frontendType) => {
    const typeMap = {
      [QuestionType.MULTIPLE_CHOICE]: 'multiple_choice',
      [QuestionType.OPEN_ENDED]: 'open',
      [QuestionType.TRUE_FALSE]: 'true_false'
    };
    return typeMap[frontendType] || 'open';
  };