import '../styles/ResearchPage.css';
import React, { useState } from 'react'; // Added useState
import Question from './QuestionView';
import { Persona, PersonaFilters } from './PersonaView';
import ResponsesView from './ResponsesView';
import { personaActions } from './ResearchActions';

const TabContent = ({
  selectedTab,
  selectedTopic,
  onQuestionEdit,
  onQuestionDelete,
  onAddQuestion,
  personas,
  setPersonas,
  onPersonaEdit,
  onPersonaDelete,
  onAddPersona,
  onNextTab,
  visibleCount,
  setVisibleCount,
  topics,
  setTopics,
  userID
}) => {
  // Ensure personas is always an array
  const personasList = Array.isArray(personas) ? personas : [];

  // Add state for view type and loading message
  const [viewType, setViewType] = useState('grid');
  const [loadingMessage, setLoadingMessage] = useState('');

  const handleApplyFilters = async (filters) => {
    try {
      console.log('Applying filters:', filters);
      
      if (!selectedTopic || !selectedTopic.id) {
        throw new Error('No topic selected');
      }
  
      // Show loading message to user
      setLoadingMessage('Generating personas...');
  
      const generatedPersonas = await personaActions.generatePersonas(
        filters,
        setPersonas,
        selectedTopic,
        setTopics,
        userID
      );
  
      console.log('Successfully generated personas:', generatedPersonas);
      setLoadingMessage('');
    } catch (error) {
      console.error('Failed to generate personas:', error);
      setLoadingMessage('');
      alert(`Failed to generate personas: ${error.message}`);
    }
  };

  switch (selectedTab) {
    case 'Questions':
      return (
        <div className="research-container">
          <h3>Questions Overview</h3>
          <div className="research-grid">
            {selectedTopic.questions.map((question) => (
              <Question
                key={question.id}
                question={question}
                onEdit={onQuestionEdit}
                onDelete={onQuestionDelete}
              />
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className="button research-button" style={{ width: '50%' }} onClick={onAddQuestion}>Add Question</button>
            <button className="button research-button" style={{ width: '50%' }} onClick={onNextTab}>Next Step</button>
          </div>
        </div>
      );
    case 'Personas':
      return (
        <div className="tab-content">
          <h2>Persona Overview</h2>
          {personasList.length === 0 ? (
            <PersonaFilters onApplyFilters={handleApplyFilters} selectedTopic={selectedTopic} setTopics={setTopics} />
          ) : (
            <div className="research-container">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>You have {personasList.length} Persona{personasList.length > 1 ? 's' : ''}</h3>
                <button
                  className="research-button"
                  onClick={() => setViewType(viewType === 'grid' ? 'list' : 'grid')}
                >
                  Switch to {viewType === 'grid' ? 'List View' : 'Grid View'}
                </button>
              </div>

              <div className={viewType === 'grid' ? "research-grid" : "research-list"}>
                {viewType === 'list' && (
                  <div className="list-header">
                    <div className="list-item">Photo</div>
                    <div className="list-item">Name</div>
                    <div className="list-item">Age</div>
                    <div className="list-item">Location</div>
                    <div className="list-item">Income</div>
                    <div className="list-item">Bio</div>
                    <div className="list-item">Actions</div>
                  </div>
                )}
                {personasList.slice(0, visibleCount).map((persona) => (
                  <Persona
                    key={persona.id}
                    persona={persona}
                    onEdit={onPersonaEdit}
                    onDelete={onPersonaDelete}
                    viewType={viewType}
                  />
                ))}
              </div>

              {personasList.length > 8 && visibleCount < personasList.length && (
                <button
                  className="research-button"
                  onClick={() => personaActions.showMorePersonas(visibleCount, setVisibleCount)}
                >
                  Show More
                </button>
              )}

              <div className="button-container">
                <button className="research-button" onClick={onAddPersona}>
                  Add Persona
                </button>
                <button className="research-button" onClick={onNextTab}>
                  Next Step
                </button>
              </div>
            </div>
          )}
        </div>
      );
    case 'Responses':
      return (
        <div className="research-container">
          <h3>Responses Overview</h3>
          <ResponsesView
            selectedTopic={selectedTopic}
            personas={personasList}
          />
          <button className="research-button" onClick={() => alert('Please upgrade your plan for premium features')}>
            Analyse Responses with AI
          </button>
        </div>
      );
    default:
      return null;
  }
};

export default TabContent;