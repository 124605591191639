import { QuestionType } from './ResearchConstants';
import { researchApi } from '../services/researchApi';
import { pollResearchStatus, mapQuestionType, mapQuestionTypeToApi } from '../services/apiUtils';

let counter = 1;
export const generateCounter = () => {
  return counter++;
};

export const generateUniqueId = () => {
  return Math.floor(Math.random() * 90000000) + 10000000;
};

export const validateFilters = (filters) => {
  return {
    ageRange: {
      min: Math.max(18, Math.min(filters.ageRange.min, 100)),
      max: Math.max(18, Math.min(filters.ageRange.max, 100))
    },
    region: filters.region || 'All',
    incomeRange: {
      min: Math.max(0, Math.min(filters.incomeRange.min, 1000000)),
      max: Math.max(0, Math.min(filters.incomeRange.max, 1000000))
    },
    count: Math.max(1, Math.min(filters.count, 1000))
  };
};

export const loadResearchData = async (userID, setTopics) => {
  try {
    // Get all research
    const researchResponse = await researchApi.getResearch(userID);
    console.log('Get all research response:', researchResponse);

    const researches = researchResponse.data.data;
    
    if (!Array.isArray(researches)) {
      console.error('Invalid research data format:', researches);
      setTopics([]);
      return;
    }

    // Get all questions for the user
    const questionsResponse = await researchApi.getQuestions(userID);
    console.log('Get questions response:', questionsResponse);

    // Map questions to their respective research topics
    const topicsWithQuestions = researches.map(research => ({
      id: research.researchID,
      name: research.researchName,
      description: research.researchDescription,
      mainQuestion: research.researchQuestion,
      questions: questionsResponse.data
        ?.filter(q => q.researchID === research.researchID)
        .map(q => ({
          id: q.questionID,
          text: q.questionText,
          type: q.questionType,
          options: q.responseOptions || []
        })) || [],
      personas: research.personas || [],
      isPremium: research.isPremium || false
    }));

    setTopics(topicsWithQuestions);
  } catch (error) {
    console.error('Failed to load research data:', error);
    setTopics([]);
  }
};

export const updateTopicInBackend = async (topic, userID) => {
  try {
    await researchApi.updateQuestions(
      userID,
      topic.id,
      topic.questions.map(q => ({
        questionID: q.id,
        questionText: q.text,
        questionType: mapQuestionTypeToApi(q.type),
        responseOptions: q.options
      }))
    );
  } catch (error) {
    console.error('Failed to update topic in backend:', error);
    throw error;
  }
};

export const topicActions = {
  addTopic: async (mainResearchQuestion, researchDescription, setTopics, setNewTopicName, setSelectedTopic, setSelectedTab, isPremium, userID) => {
    try {
      
      // Create research in backend
      const response = await researchApi.createResearch(
        userID,
        mainResearchQuestion,
        researchDescription,
        mainResearchQuestion
      );

      if (!response || !response.data) {
        console.error('Server response:', response);
        throw new Error('No response data received from server');
      }
      
      if (!response.data.data?.researchID) {
        console.error('Invalid response format:', response.data);
        throw new Error(`Missing researchID in response. Received: ${JSON.stringify(response.data)}`);
      }

      const researchID = response.data.data.researchID;

      // Wait for questions to be generated
      await pollResearchStatus(userID, researchID);
      
      // Get questions for this research
      const questionsResponse = await researchApi.getQuestions(userID);
      const researchQuestions = questionsResponse.data.filter(
        q => q.researchID === researchID
      );

      // Create new topic with generated questions
      const newTopic = {
        id: researchID,
        name: mainResearchQuestion,
        isPremium,
        questions: researchQuestions.map(q => ({
          id: q.questionID,
          text: q.questionText,
          type: mapQuestionType(q.questionType),
          options: q.responseOptions
        })),
        personas: []
      };

      setTopics(prev => [...prev, newTopic]);
      setNewTopicName('');
      setSelectedTopic(newTopic);
      setSelectedTab('Questions');
    } catch (error) {
      console.error('Failed to create topic:', error);
      // Optionally show user-friendly error message
      alert('Failed to create research. Please try again.');
    }
  },

  newResearch: (setSelectedTopic, setSelectedTab) => {
    setSelectedTopic(null);
    setSelectedTab('Questions');
  },

  deleteTopic: async (topicId, setTopics, setSelectedTopic, userID) => {
    try {
      await researchApi.deleteResearch(topicId, userID);
      setTopics(prev => prev.filter(t => t.id !== topicId));
      setSelectedTopic(null);
    } catch (error) {
      console.error('Failed to delete topic:', error);
    }
  },

  upgradeToPremium: async (selectedTopic, setTopics, setSelectedTopic, userID) => {
    try {
      await researchApi.upgradeResearchToPremium(selectedTopic.id, userID);
      setTopics(prev => prev.map(t => 
        t.id === selectedTopic.id ? { ...t, isPremium: true } : t
      ));
      setSelectedTopic({ ...selectedTopic, isPremium: true });
    } catch (error) {
      console.error('Failed to upgrade topic to premium:', error);
    }
  }
};

export const questionActions = {
  addQuestion: async (selectedTopic, setSelectedTopic, setTopics, userID) => {
    try {
      const newQuestion = {
        id: generateCounter(),
        text: 'New Question',
        type: QuestionType.OPEN_ENDED
      };
      await researchApi.addQuestion(selectedTopic.id, newQuestion, userID);
      setSelectedTopic(updatedTopic => {
        const newTopic = {
          ...updatedTopic,
          questions: [...updatedTopic.questions, newQuestion]
        };
        setTopics(prev => prev.map(t => 
          t.id === selectedTopic.id ? newTopic : t
        ));
        return newTopic;
      });
    } catch (error) {
      console.error('Failed to add question:', error);
    }
  },

  editQuestion: async (questionId, updates, selectedTopic, setSelectedTopic, setTopics, userID) => {
    try {
      await researchApi.editQuestion(selectedTopic.id, questionId, updates, userID);
      setSelectedTopic(updatedTopic => {
        const newTopic = {
          ...updatedTopic,
          questions: updatedTopic.questions.map(q =>
            q.id === questionId ? { ...q, ...updates } : q
          )
        };
        setTopics(prev => prev.map(t =>
          t.id === selectedTopic.id ? newTopic : t
        ));
        return newTopic;
      });
    } catch (error) {
      console.error('Failed to edit question:', error);
    }
  },

  deleteQuestion: async (questionId, selectedTopic, setSelectedTopic, setTopics, userID) => {
    try {
      await researchApi.deleteQuestion(selectedTopic.id, questionId, userID);
      setSelectedTopic(updatedTopic => {
        const newTopic = {
          ...updatedTopic,
          questions: updatedTopic.questions.filter(q => q.id !== questionId)
        };
        setTopics(prev => prev.map(t =>
          t.id === selectedTopic.id ? newTopic : t
        ));
        return newTopic;
      });
    } catch (error) {
      console.error('Failed to delete question:', error);
    }
  }
};

export const personaActions = {
  addPersona: async (personas, setPersonas, selectedTopic, setTopics, userID) => {
    try {
      const newPersona = {
        id: generateUniqueId(),
        number: personas.length + 1,
        name: `Persona ${personas.length + 1}`,
        age: 30,
        location: 'United States',
        income: 50000,
        bio: 'Add bio'
      };
      await researchApi.addPersona(selectedTopic.id, newPersona, userID);
      setPersonas(prev => [...prev, newPersona]);
      setTopics(prev => prev.map(t =>
        t.id === selectedTopic.id ? { ...t, personas: [...t.personas, newPersona] } : t
      ));
    } catch (error) {
      console.error('Failed to add persona:', error);
    }
  },

  editPersona: async (personaId, updates, personas, setPersonas, selectedTopic, setTopics, userID) => {
    try {
      await researchApi.editPersona(selectedTopic.id, personaId, updates, userID);
      setPersonas(prev => prev.map(p =>
        p.id === personaId ? { ...p, ...updates } : p
      ));
      setTopics(prev => prev.map(t =>
        t.id === selectedTopic.id ? { ...t, personas: prev } : t
      ));
    } catch (error) {
      console.error('Failed to edit persona:', error);
    }
  },

  deletePersona: async (personaId, personas, setPersonas, selectedTopic, setTopics, userID) => {
    try {
      await researchApi.deletePersona(selectedTopic.id, personaId, userID);
      setPersonas(prev => prev.filter(p => p.id !== personaId));
      setTopics(prev => prev.map(t =>
        t.id === selectedTopic.id ? { ...t, personas: prev } : t
      ));
    } catch (error) {
      console.error('Failed to delete persona:', error);
    }
  },

  generatePersonas: async (filters, setPersonas, selectedTopic, setTopics, userID) => {
    try {
      console.log('Generating personas with filters:', filters);
      
      // Initialize personas state if needed
      setPersonas([]);
      
      const response = await researchApi.createPersonas(
        selectedTopic.id,
        filters.count,
        filters
      );
      
      console.log('Create personas response:', response);
      
      if (!response.personas || !Array.isArray(response.personas)) {
        throw new Error('Invalid response format from server');
      }

      const generatedPersonas = response.personas.map(persona => ({
        id: generateUniqueId(),
        number: persona.number || Math.floor(Math.random() * 1000),
        name: persona.name || 'Unknown',
        age: persona.age || 25,
        location: persona.location || 'Unknown',
        income: persona.income || 0,
        bio: persona.bio || 'No bio available'
      }));

      console.log('Generated personas:', generatedPersonas);

      // Update personas state
      setPersonas(generatedPersonas);
      
      // Update topics state
      if (setTopics) {
        setTopics(prev => prev.map(t =>
          t.id === selectedTopic.id 
            ? { ...t, personas: generatedPersonas }
            : t
        ));
      }

      return generatedPersonas;
    } catch (error) {
      console.error('Failed to generate personas:', error);
      setPersonas([]); // Reset personas on error
      throw error;
    }
  },

  
  showMorePersonas: (currentVisible, setVisibleCount) => {
    setVisibleCount(Math.max(currentVisible + 2, 100));
  }
};
