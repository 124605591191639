import React, { useEffect, useRef } from 'react';
import '../styles/HomePage.css';

function AnimatedHero() {
  const crowdContainerRef = useRef(null);
  const figuresRef = useRef([]);
  const animationRef = useRef(null);

  useEffect(() => {
    const container = crowdContainerRef.current;
    const numFigures = 50;

    // Create figures
    for (let i = 0; i < numFigures; i++) {
      const figure = document.createElement('div');
      figure.className = 'stick-figure';
      figure.textContent = '👤'; // Use walking person emoji
      
      // Set random initial position
      figure.style.left = `${Math.random() * 100}%`;
      figure.style.bottom = `${Math.random() * 20}%`;
      
      container.appendChild(figure);
      figuresRef.current.push(figure);
    }

    // Animate figures
    const animate = () => {
      const midpoint = Math.floor(figuresRef.current.length / 2);
      const avgLeftFirstHalf = figuresRef.current.slice(0, midpoint).reduce((sum, figure) => {
        return sum + parseFloat(figure.style.left);
      }, 0) / midpoint;
      const avgLeftSecondHalf = figuresRef.current.slice(midpoint).reduce((sum, figure) => {
        return sum + parseFloat(figure.style.left); 
      }, 0) / (figuresRef.current.length - midpoint);

      figuresRef.current.forEach(figure => {
        const currentLeft = parseFloat(figure.style.left);
        // Add random wave movement left and right
        const randomOffset = (Math.random() < 0.5 ? -1 : 1) * 0.3; // Random value between -0.2 and 0.2, randomly negated
        const relativeOffset1 = Math.abs(avgLeftFirstHalf - currentLeft) > 0.5 ? 0 : (avgLeftFirstHalf - currentLeft) * 0.002;
        const relativeOffset2 = Math.abs(avgLeftSecondHalf - currentLeft) > 0.5 ? 0 : (avgLeftSecondHalf - currentLeft) * 0.002;
        const newPosition = currentLeft + relativeOffset1 + relativeOffset2 + randomOffset;
        
        // Keep figures within bounds and loop
        if (newPosition >= 95) {
          figure.style.left = '90%';
        } else if (newPosition <= 5) {
          figure.style.left = '5%';
        } else {
          figure.style.left = `${newPosition}%`;
        }
      });
      
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    // Cleanup
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      container.innerHTML = '';
      figuresRef.current = [];
    };
  }, []);

  return (
    <div ref={crowdContainerRef} className="crowd-container" />
  );
}

export default AnimatedHero;