import { useState } from 'react';

export const useFormSubmit = () => {
  const [submitStatus, setSubmitStatus] = useState({ message: '', isError: false });

  const handleSubmit = async (e, formId) => {
    e.preventDefault();
    const form = document.getElementById(formId);
    
    try {
      console.log('Sending form data to:', form.action);
      const response = await fetch(form.action, {
        method: 'POST',
        body: new FormData(form)
      });
      
      const data = await response.json();
      console.log('Response data:', data);

      if (data.result === 'success') {
        setSubmitStatus({
          message: 'Thank you for your submission!',
          isError: false
        });
        form.reset();
      } else {
        setSubmitStatus({
          message: 'There was an error. Please try again.',
          isError: true
        });
      }
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus({
        message: 'There was an error. Please try again.',
        isError: true
      });
    }
  };

  return { submitStatus, handleSubmit };
};