import React from 'react';
import '../index.css';
import '../styles/AboutPage.css';
import InstantFlowsLogoSub from '../components/logos/InstantFlowsLogo - subtitle.png';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

import Jorrit from '../components/team/Jorrit_profile.png';
import Odylia from '../components/team/Odylia_profile.png';
import George from '../components/team/George_profile.png';
import AnnaMaria from '../components/team/Annamaria_profile.png';
import Priscille from '../components/team/Priscille_profile.png';
import Dennis from '../components/team/Dennis_profile.png';
import Bram from '../components/team/Bram_profile.png';
import Nicolas from '../components/team/Nicolas_profile.png';
import Jip from '../components/team/Jip_profile.png';

function AboutPage() {

  const{t, i18n} = useTranslation() ;

    useEffect(() => {
      i18n.changeLanguage(navigator.language);
    }, []);


  return (
    <div className="about-page">
      <div className="hero hero-1 top-hero">
        <h1>About Us</h1>
        <div className="content-grid-1">
          <div className="container">
            <p>InstantResearch is an AI-powered market research platform developed by InstantFlows. Our company was founded by a team of technical experts, business strategists, and marketing professionals to address the growing demand for faster, more affordable, and efficient market research solutions. We recognise the limitations of traditional market research methods, which are often expensive, time-consuming, and lack scalability, particularly for small and medium-sized businesses (SMBs).</p>
          </div>
          <div className="container">
            <h3>Our Expertise</h3>
            <p>We operate as a highly collaborative team, leveraging our diverse skills and expertise in areas such as marketing, AI, software and business development.</p>
          </div>
          <div className="container">
            <h3>Our Focus</h3>
            <p>We operate with a focus on agile development, user-centric design, and data security and privacy, ensuring we are consistently delivering value and adapting to evolving market needs.</p>
          </div>
          <div className="container">
            <h3>Our Values</h3>
            <p>Our values are centered around innovation, customer focus, and collaboration, driving us to push the boundaries of what's possible in market research.</p>
          </div>
          <div className="container">
            <h3>Goals and Ambitions</h3>
            <p>Our primary goal is to make market research more accessible, efficient, and affordable for businesses of all sizes. We aim to achieve this by continuously improving the accuracy and reliability of our AI-powered persona generation and response simulation, expanding our platform's functionality, and establishing InstantFlows as a leading provider of AI-driven market research solutions.</p>
          </div>
          <div className="container">
            <h3>Origins</h3>
            <p>InstantResearch was born out of a recognition of the limitations of traditional market research methods and a belief in the transformative potential of AI technology. Our team came together with a shared vision of creating a platform that could empower businesses with the insights they need to thrive in a rapidly evolving market landscape.</p>
          </div>
        </div>
      </div>

      <div className="team-section">
        <h2>Meet Our Team</h2>
        <div className="team-carousel">
          <div className="team-images">
            <img src={Jorrit} alt="Jorrit" />
            <img src={Odylia} alt="Odylia" />
            <img src={George} alt="George" />
            <img src={AnnaMaria} alt="AnnaMaria" />
            <img src={Priscille} alt="Priscille" />
            <img src={Dennis} alt="Dennis" />
            <img src={Bram} alt="Bram" />
            <img src={Nicolas} alt="Nicolas" />
            <img src={Jip} alt="Jip" />
            <img src={Jorrit} alt="Jorrit" />
            <img src={Odylia} alt="Odylia" />
            <img src={George} alt="George" />
            <img src={AnnaMaria} alt="AnnaMaria" />
            <img src={Priscille} alt="Priscille" />
            <img src={Dennis} alt="Dennis" />
            <img src={Bram} alt="Bram" />
            <img src={Nicolas} alt="Nicolas" />
            <img src={Jip} alt="Jip" />
            <img src={Jorrit} alt="Jorrit" />
          </div>
        </div>
        <div className="careers-link">
          <h3>Join Our Team</h3>
          <p>Interested in working with us? Check out our career opportunities.</p>
          <a href="/careers">Careers</a>
        </div>
      </div>

      <div className="hero hero-1">      
          <h2>How the Instant Research Service Works</h2> 
          <div className="textbox">
            <h3>1. Create Your Research Topic:</h3> 
            <p>Start by defining what you want to research - this could be a product, service, or concept you want to understand better.</p>
          </div>
          <div className="textbox">
            <h3>2. Design Your Survey:</h3> 
            <p>Create your research questions with our flexible question builder. Choose from open-ended, multiple choice, or true/false questions to gather the insights you need.</p>
          </div>
          <div className="textbox">
            <h3>3. Define Your Target Audience:</h3> 
            <p>Use our demographic filters to specify your ideal respondents - including age range, location, and income level. Our AI will generate detailed personas matching your criteria.</p>
          </div>
          <div className="textbox">
            <h3>4. Get AI-Generated Responses:</h3> 
            <p>Our AI simulates responses from your created personas, providing realistic feedback based on their demographic profiles and characteristics.</p>
          </div>
          <div className="textbox">
            <h3>5. Analyze Results:</h3> 
            <p>Review responses across all your personas and use our AI analysis tools to uncover key insights and patterns in your research data.</p>
          </div>
      </div>
        
    </div>
  );
}

export default AboutPage;
