// client/src/components/Research/ResponsesView.js
import React, { useEffect, useState } from 'react';
import { researchApi } from '../services/researchApi';

const ResponsesView = ({ selectedTopic, personas }) => {
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const generateResponses = async () => {
      try {
        const result = await researchApi.generateAnswers(
          selectedTopic.questions,
          personas
        );
        setResponses(result.responses);
        setLoading(false);
      } catch (err) {
        setError('Failed to generate responses');
        setLoading(false);
      }
    };

    if (personas.length > 0) {
      generateResponses();
    }
  }, [selectedTopic, personas]);

  if (loading) return <div>Generating responses...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="research-grid">
      {selectedTopic.questions.map(question => (
        <div key={question.id} className="research-grid-item">
          <h4>{question.text}</h4>
          <div className="grid-content">
            {personas.map(persona => (
              <div key={persona.id} className="grid-details">
                <strong>{persona.name}:</strong> {responses[persona.id]?.[question.id] || 'No response'}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResponsesView;