import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/AuthContext';
import { SUBSCRIPTION_TYPES, CREDIT_PACKAGES } from './PaymentUtils';
import CreditTopup from './CreditTopup';
import SubscriptionPayment from './SubscriptionPayment';
import { StripeWrapper } from '../components/StripeWrapper';

function PaymentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, currentSubscription } = useAuth();
  const [paymentType, setPaymentType] = useState('subscription');
  
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    
    if (location.state?.type) {
      setPaymentType(location.state.type);
    }
  }, [user, navigate, location]);

  return (
    <div className="payment-page">
      <div className="hero hero-1 top-hero">
        <h1>{paymentType === 'subscription' ? 'Choose Your Plan' : 'Top Up Credits'}</h1>
        
        <StripeWrapper>
          {paymentType === 'subscription' ? (
            <SubscriptionPayment 
              selectedPlan={location.state?.plan}
              subscriptionTypes={SUBSCRIPTION_TYPES}
              currentSubscription={currentSubscription}
            />
          ) : (
            <CreditTopup 
              creditPackages={CREDIT_PACKAGES}
            />
          )}
        </StripeWrapper>
      </div>
    </div>
  );
}

export default PaymentPage;