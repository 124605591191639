import React, { useState, useEffect } from 'react';
import { useAuth } from '../authentication/AuthContext';
import { PaymentForm } from './PaymentForm';
import { StripeWrapper } from '../components/StripeWrapper';
import { paymentApi } from '../services/paymentApi';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

function SubscriptionPayment({ selectedPlan, subscriptionTypes, currentSubscription }) {
  const [selectedPlanState, setSelectedPlanState] = useState(selectedPlan || 'BASIC');
  const [error, setError] = useState(null);
  const { user, credits, updateUserAttributes, subscription, sub } = useAuth();

  const stripe = useStripe();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Subscription state:', {
      subscription,
      selectedPlanState,
      selectedPlan
    });
  }, [currentSubscription, selectedPlanState, selectedPlan]);

  const handlePayment = async (paymentMethod) => {
    try {
      console.log('Starting payment process:', {
        selectedPlan: selectedPlanState,
        paymentMethodId: paymentMethod.id
      });
      
      const response = await paymentApi.createSubscription(
        selectedPlanState,
        paymentMethod.id,
        user.email
      );
  
      console.log('Server response:', response);
  
      // For existing subscriptions that were updated
      if (response.status === 'subscription_updated') {
        alert('Subscription updated successfully!');
        navigate('/account');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return;
      }
  
      // For new subscriptions that need payment confirmation
      if (response.clientSecret) {
        const { error: confirmError } = await stripe.confirmCardPayment(
          response.clientSecret
        );
  
        if (confirmError) {
          throw confirmError;
        }
      }
  
      alert('Subscription processed successfully!');
      navigate('/account');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
  
    } catch (error) {
      console.error('Payment process error:', {
        error,
        message: error.message,
        stack: error.stack
      });
      alert(`Payment failed: ${error.message}`);
    }
  };
  
  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <StripeWrapper>
      <div>
        <div className="pricing-container">
          {Object.entries(subscriptionTypes)
            .filter(([key]) => key !== 'FREE')
            .map(([key, value]) => (
              <div 
                key={key}
                className={`pricing-card ${selectedPlanState === key ? 'selected' : ''}`}
                onClick={() => setSelectedPlanState(key)}
              >
                <h3>{value.name}</h3>
                <p className="price">€{value.price}/month</p>
                <p className="billing-cycle">Billed monthly from today</p>
                <p className="credits-info">Credits reset every Sunday</p>
                <ul>
                  {value.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            ))} 
        </div>
        <div>
        <PaymentForm 
          amount={subscriptionTypes[selectedPlanState]?.price || 0}
          onSuccess={handlePayment}
          onError={(error) => alert(error.message)}
        />
        {currentSubscription !== 'FREE' && (
          <div className="container textbox">
            <h3>Cancel your subscription?</h3>
            <p>Monthly billing will be stopped and your subscription will remain active until the end of the current billing period.</p>
            <button 
              className="button"
              onClick={() => {
                if (window.confirm('Are you sure you want to cancel your subscription? You will lose access to your subsciption features at the end of your current billing period.')) {
                  setSelectedPlanState('CANCEL');
                  handlePayment({ id: 'CANCEL' });
                }
              }}
            >
              Cancel Subscription
            </button>
          </div>
        )}
        </div>
      </div>
    </StripeWrapper>
  );
}

export default SubscriptionPayment;